import { useQuery } from '@tanstack/react-query';
import { QueryKeyString } from '../enums/queryKey.enum';
import { EnrollmentTrackingSvc } from '../services/enrollment.service';

export const useYearlyEnrollments = (previewID?:string) => {
    return useQuery({
      queryKey: [QueryKeyString.Yearly_Enrollment,{ previewID }],
      queryFn: () => EnrollmentTrackingSvc.fetchYearlyEnrollments(previewID),
      
    });
  };