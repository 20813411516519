import React from "react";
import { TbCamera } from "react-icons/tb";
import ReactDatePicker from "../../../shared/components/reactDatePicker/ReactDatePicker";
import { PiCalendarPlus } from "react-icons/pi";
import ReactSelect from "../../../foray_module/components/reactSelect/ReactSelect";

const EmployeeInformationForm = () => {
  return (
    <>
      <div className="bg-white p-6 rounded-xl">
        <h2 className="text-xl text-secondary font-semibold mb-9">
          Fill Out The Information
        </h2>
        <div className="grid grid-cols-12 gap-9">
          <div className="col-span-3">
            <div className="bg-white rounded-xl space-y-3">
              <img
                className="w-[218px] h-[228px] rounded-lg border-secondaryVariant2 border-[0.3px]"
                src="https://a360csastorage.blob.core.windows.net/childcare/8669bd41-eb7e-48c2-b750-e7cfdff8cbde-fileName-dummyStudent-img.png"
                alt="student"
              />

              <div className="text-start">
                <label className="text-base text-white bg-secondary border-[0.5px] border-secondaryVariant2 rounded-lg py-3 px-4 cursor-pointer flex items-center justify-center gap-2 max-w-[218px]">
                  <TbCamera className="w-6 h-6" />
                  Upload Photo{" "}
                  <input type="file" accept="image/*" className="hidden" />
                </label>
              </div>
            </div>
          </div>
          <div className="col-span-9">
            <div className="grid grid-cols-2 gap-x-7 gap-y-9 mb-9">
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  First Name*{" "}
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                />
              </div>
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  Middle Name{" "}
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                />
              </div>
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  Last Name*{" "}
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                />
              </div>
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  Preferred Name{" "}
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                />
              </div>
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  Email*{" "}
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                />
              </div>
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  Mobile Number*{" "}
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                />
              </div>
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  Date of Birth
                </label>
                <div className="w-full relative">
                  <ReactDatePicker
                    placeholder="MM/DD/YYYY"
                    maxDate={new Date()}
                  />
                  <PiCalendarPlus className="w-5 h-5 absolute right-3 top-4 pointer-events-none" />
                </div>
              </div>
              <div>
                <ReactSelect label="Gender" placeholder="Gender " required />
              </div>
            </div>
            <div className="space-y-9 mb-9">
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  SSN (Social Security Number){" "}
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                />
              </div>
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  Current Street Address{" "}
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-x-7 gap-y-9 mb-9">
              <div>
                <ReactSelect
                  label="Citizenship"
                  placeholder="Citizenship "
                  required
                />
              </div>
              <div>
                <ReactSelect label="City" placeholder="City " required />
              </div>
              <div>
                <ReactSelect label="State" placeholder="State " required />
              </div>
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  Zip Code{" "}
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                />
              </div>
            </div>
            <div className="flex items-center gap-3">
              <button className="btnPrimary max-w-[181px]">Save</button>
              <button className="btnSimple max-w-[181px]">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeInformationForm;
