import { HoverCard, Text } from "@radix-ui/themes";
import React, { useState } from "react";
import { CgChevronRight } from "react-icons/cg";
import {
  FiArrowDown,
  FiArrowDownRight,
  FiArrowUp,
  FiArrowUpRight,
} from "react-icons/fi";
import { IoMdArrowDropdown } from "react-icons/io";
import {
  Classroom,
  DailyCount,
  Transition,
} from "../../../types/IEnrollmentTrackingInfo";
import { IEnrollmentSwitchesState } from "../../types/IEnrollmentSwitches";
import StudentExecutionDetailDialog from "../modals/StudentExecutionDetail";
import { calculateAge } from "../../../../shared/utils/formatDate";
import { StudentExecutions } from "../../enums/studentExecutions.enum";

interface CalendarDataProps {
  toggleTable: () => void;
  dailyCounts: DailyCount;
  classroom: Classroom;
  isPartTime?: boolean;
  switches: IEnrollmentSwitchesState;
}

const CalendarData: React.FC<CalendarDataProps> = ({
  toggleTable,
  dailyCounts,
  classroom,
  isPartTime,
  switches,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedTransition, setSelectedTransition] = useState<Transition>();
  const openModal = () => setModalOpen(true);
  const [selectedTransitonType, setSelectedTransionType] =
    useState<StudentExecutions>();

  return (
    <div className="flex">
      <div
        className={`ps-2 pt-2 w-[65px] ${
          dailyCounts.date ? "bg-[#F1F8FF]" : "bg-secondaryVariant2"
        } flex-[0_0_65px] min-h-[66px]`}
      >
        {/* transitions in */}
        {dailyCounts.date ? (
          <div className="flex items-start justify-between">
            {switches.movements && dailyCounts.transitions.length > 0 && (
              <Text>
                <HoverCard.Root>
                  <HoverCard.Trigger>
                    <button>
                      <FiArrowDownRight className="w-3 h-3 text-arrowColor" />
                    </button>
                  </HoverCard.Trigger>
                  <HoverCard.Content
                    maxWidth="170px"
                    className="bg-secondaryVariant rounded-lg p-2 shadow-lg"
                  >
                    {dailyCounts.transitions.map((transition) => {
                      const age = calculateAge(
                        transition.dateOfBirth as string
                      );
                      return (
                        <>
                          <div className="flex items-start justify-between">
                            <div className="max-w-[133px]">
                              <p className="text-sm text-white mb-4">
                                {`${transition.firstName}, ${transition.lastName}, Age: ${age}`}
                              </p>
                              <button
                                onClick={() => {
                                  setSelectedTransition(transition);
                                  setSelectedTransionType(
                                    StudentExecutions.Transition_In
                                  );
                                  openModal();
                                }}
                                className="text-sm text-white bg-primary rounded px-2 py-1"
                              >
                                Execute
                              </button>
                            </div>
                            <FiArrowDownRight className="w-3 h-3 text-white" />
                          </div>
                          <hr className="my-3 text-secondaryVariant2" />
                        </>
                      );
                    })}
                  </HoverCard.Content>
                </HoverCard.Root>
              </Text>
            )}

            {/* transition out */}
            {switches.movements && dailyCounts.transitionsOut.length > 0 && (
              <Text>
                <HoverCard.Root>
                  <HoverCard.Trigger>
                    <button>
                      <FiArrowUpRight className="w-3 h-3 text-arrowColor" />
                    </button>
                  </HoverCard.Trigger>
                  <HoverCard.Content
                    maxWidth="170px"
                    className="bg-secondaryVariant rounded-lg p-2 shadow-lg"
                  >
                    {dailyCounts.transitionsOut.map((transition) => {
                       const age = calculateAge(
                        transition.dateOfBirth as string
                      );
                      return (
                        <>
                          <div className="flex items-start justify-between">
                            <div className="max-w-[133px]">
                              <p className="text-sm text-white mb-4">
                                {`${transition.firstName}, ${transition.lastName}, Age: ${age}`}
                              </p>
                              <button
                                onClick={() => {
                                  setSelectedTransition(transition);
                                  setSelectedTransionType(
                                    StudentExecutions.Transition_Out
                                  );
                                  openModal();
                                }}
                                className="text-sm text-white bg-primary rounded px-2 py-1"
                              >
                                Execute
                              </button>
                            </div>
                            <FiArrowUpRight className="w-3 h-3 text-white" />
                          </div>
                          <hr className="my-3 text-secondaryVariant2" />
                        </>
                      );
                    })}
                  </HoverCard.Content>
                </HoverCard.Root>
              </Text>
            )}
            {/* Starts */}
            {switches.movements && dailyCounts.starts.length > 0 && (
              <Text>
                <HoverCard.Root>
                  <HoverCard.Trigger>
                    <button>
                      <FiArrowDown className="w-3 h-3 text-arrowColor" />
                    </button>
                  </HoverCard.Trigger>
                  <HoverCard.Content
                    maxWidth="170px"
                    className="bg-secondaryVariant rounded-lg p-2 shadow-lg"
                  >
                    {dailyCounts.starts.map((start) => {
                       const age = calculateAge(
                        start.dateOfBirth as string
                      );
                      return (
                        <>
                          <div className="flex items-start justify-between">
                            <div className="max-w-[133px]">
                              <p className="text-sm text-white mb-4">
                                {`${start.firstName}, ${start.lastName}, Age: ${age}`}
                              </p>
                              <button
                                onClick={() => {
                                  setSelectedTransition(start);
                                  setSelectedTransionType(
                                    StudentExecutions.Start
                                  );
                                  openModal();
                                }}
                                className="text-sm text-white bg-primary rounded px-2 py-1"
                              >
                                Execute
                              </button>
                            </div>
                            <FiArrowDown className="w-3 h-3 text-white" />
                          </div>
                          <hr className="my-3 text-secondaryVariant2" />
                        </>
                      );
                    })}
                  </HoverCard.Content>
                </HoverCard.Root>
              </Text>
            )}

            {/* Withdraw */}
            {switches.movements && dailyCounts.withDrawals.length > 0 && (
              <Text>
                <HoverCard.Root>
                  <HoverCard.Trigger>
                    <button>
                      <FiArrowUp className="w-3 h-3 text-arrowColor" />
                    </button>
                  </HoverCard.Trigger>
                  <HoverCard.Content
                    maxWidth="170px"
                    className="bg-secondaryVariant rounded-lg p-2 shadow-lg"
                  >
                    {dailyCounts.withDrawals.map((withdraw) => {
                          const age = calculateAge(
                            withdraw.dateOfBirth as string
                          );
                      return (
                        <>
                          <div className="flex items-start justify-between">
                            <div className="max-w-[133px]">
                              <p className="text-sm text-white mb-4">
                                {`${withdraw.firstName}, ${withdraw.lastName}, Age: ${age}`}
                              </p>
                              <button
                                onClick={() => {
                                  setSelectedTransition(withdraw);
                                  setSelectedTransionType(
                                    StudentExecutions.Withdraw
                                  );
                                  openModal();
                                }}
                                className="text-sm text-white bg-primary rounded px-2 py-1"
                              >
                                Execute
                              </button>
                            </div>
                            <FiArrowUp className="w-3 h-3 text-white" />
                          </div>
                          <hr className="my-3 text-secondaryVariant2" />
                        </>
                      );
                    })}
                  </HoverCard.Content>
                </HoverCard.Root>
              </Text>
            )}
            {/* {isModalOpen && (
              <StudentExecutionDetailDialog
                isOpen={isModalOpen}
                onClose={closeModal}
              />
            )} */}
            {/* 369EFF */}
            <div className="flex flex-col items-end gap-1">
              {isPartTime ? (
                <div
                  className={` ${
                    dailyCounts.partTimeStudentsCount >
                    classroom.licenseCapacity
                      ? "bg-overBooked"
                      : dailyCounts.partTimeStudentsCount ===
                        classroom.licenseCapacity
                      ? "bg-white"
                      : "bg-[#369EFF]"
                  } text-base ${
                    dailyCounts.partTimeStudentsCount ===
                    classroom.licenseCapacity
                      ? "text-black"
                      : "text-white"
                  } font-semibold inline-flex items-center justify-center h-[24px] pe-3 ps-2.5 rounded rounded-l-full`}
                >
                  {switches.enrollmentNo
                    ? dailyCounts.partTimeStudentsCount
                    : ""}
                </div>
              ) : (
                <div
                  className={` ${
                    dailyCounts.fullTimeStudentsCount >
                    classroom.licenseCapacity
                      ? "bg-overBooked"
                      : dailyCounts.fullTimeStudentsCount ===
                        classroom.licenseCapacity
                      ? "bg-white"
                      : "bg-[#369EFF]"
                  } text-base ${
                    dailyCounts.fullTimeStudentsCount ===
                    classroom.licenseCapacity
                      ? "text-black"
                      : "text-white"
                  } font-semibold inline-flex items-center justify-center h-[24px] pe-3 ps-2.5 rounded rounded-l-full`}
                >
                  {switches.enrollmentNo
                    ? dailyCounts.fullTimeStudentsCount
                    : ""}
                </div>
              )}
              {!isPartTime &&
                switches.ageViolation &&
                dailyCounts.countStudentsOutsideMaximumRange >
                  classroom.countAllowedOutsideMaximumRange && (
                  <div className="bg-ageViolation text-xs text-secondary font-semibold py-1 h-[17px] pe-3 ps-1.5 rounded rounded-l-full flex items-center">
                    <CgChevronRight className="w-3 h-3" />
                    {dailyCounts.countStudentsOutsideMaximumRange}
                  </div>
                )}

              {switches.optimization && (
                <div className="text-end">
                  <button className="inline-block" onClick={toggleTable}>
                    <IoMdArrowDropdown className="text-[#D3D3D3]" size={18} />
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : null}
      </div>
      {isModalOpen && (
        <StudentExecutionDetailDialog
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          selectedTransition={selectedTransition}
          selectedTransitionType={selectedTransitonType}
        />
      )}
    </div>
  );
};

export default CalendarData;
