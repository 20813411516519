import { lazy, Suspense } from "react";
import { RouteObject } from "react-router-dom";
import LoaderSpinner from "../shared/components/Loader/Loader";
import { forayRoutes } from "./routes/routes";
// import ViewStudentProfile from "./pages/viewStudentProfile/ViewStudentProfile";
import AddInterview from "../hr-setup_modules/pages/addInterview/AddInterview";
import EmployeePersonalInformation from "../hr-setup_modules/pages/personalInformation/EmployeePersonalInformation";
import EditStudentDetail from "./enrollment_Tracking/pages/enrollmentTracking/EditStudentDetail";
import EnrollmentTracking from "./enrollment_Tracking/pages/enrollmentTracking/EnrollmentTracking";
import ForayScreen from "./pages/foray/ForayScreen";
import ParentInfo from "./pages/parentInfo/ParentInfo";
import HrDashboard from "../hr-setup_modules/pages/hr/HrDashboard";

const CenterSetup = lazy(() => import("./pages/centerSetup/CenterSetup"));
const ForayCenterView = lazy(
  () => import("./pages/centerView/ForayCenterView")
);
const ClassroomSetup = lazy(
  () => import("./pages/classroomSetup/ClassroomSetup")
);
const ClassroomList = lazy(() => import("./pages/classroomList/ClassroomList"));
const ViewClassroom = lazy(() => import("./pages/viewClassroom/ViewClassroom"));
const StudentSetup = lazy(() => import("./pages/studentSetup/StudentSetup"));
const StudentList = lazy(() => import("./pages/studentList/StudentList"));
const ViewStudentProfile = lazy(
  () => import("./pages/viewStudentProfile/ViewStudentProfile")
);

const ForayModuleRoutes: RouteObject[] = [
  {
    path: forayRoutes.center_setup,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <CenterSetup />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.center_modify,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <CenterSetup />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.center_view,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ForayCenterView />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.classroom_setup,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ClassroomSetup />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.classroom_modify,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ClassroomSetup />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.classroom_list,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ClassroomList />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.view_classroom,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ViewClassroom />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.student_setup,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <StudentSetup />
      </Suspense>
    ),
  },
  // {
  //   path: forayRoutes.student_modify,
  //   element: (
  //     <Suspense fallback={<LoaderSpinner />}>
  //       <StudentSetup />
  //     </Suspense>
  //   ),
  // },
  {
    path: forayRoutes.sibling_setup,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <StudentSetup />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.student_list,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <StudentList />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.view_student_profile,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ViewStudentProfile />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.parent_info,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ParentInfo />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.enrollment_tracking,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <EnrollmentTracking />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.foray_screen,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <ForayScreen />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.employee_personal_information,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <EmployeePersonalInformation />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.add_interview,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <AddInterview />
      </Suspense>
    ),
  },

  {
    path: forayRoutes.student_detail_edit,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <EditStudentDetail />
      </Suspense>
    ),
  },

  {
    path: forayRoutes.hr_dashboard,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <HrDashboard />
      </Suspense>
    ),
  },
];

export default ForayModuleRoutes;
