import { FirstTransition } from "../types/IEnrollmentTrackingInfo";
import {
  Contact2,
  IStudentRegistration,
  Parent,
  ParentInfo,
  StudentInfoDetailed,
} from "../types/IStudentSetup.interface";
import { apiForay } from "../utils/axios";

const postStudentSetup = async (reqBody: IStudentRegistration) => {
  try {
    const res: any = await apiForay.post(`/student-setup`, reqBody);
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("Couldn't create student setup");
  } catch (err) {
    console.log("error: ", err);
  }
};
const postSiblingSetup = async (
  reqBody: IStudentRegistration,
  studentId?: string
) => {
  try {
    const res: any = await apiForay.post(
      `/student-setup/addSibling${studentId ? "/" + studentId : ""}`,
      reqBody?.student
    );
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("Couldn't create student setup");
  } catch (err) {
    console.log("error: ", err);
  }
};
const updateStudentSetup = async (
  id: string,
  studentSetup: StudentInfoDetailed,
  parentSetup: ParentInfo | undefined
) => {
  let payload: { student: StudentInfoDetailed; parentSetup?: ParentInfo[] } = {
    student: studentSetup,
  };
  if (parentSetup) {
    payload = { ...payload, parentSetup: [parentSetup] };
  }
  try {
    const res: any = await apiForay.put(`/student-setup/${id}`, payload);
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("Couldn't create student setup");
  } catch (err) {
    console.log("error: ", err);
  }
};
const fetchStudentsInfo = async () => {
  try {
    const res: any = await apiForay.get(`/student-setup/info`);
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("Couldn't get students info");
  } catch (err) {
    console.log("error: ", err);
  }
};
const fetchDefaultTransitions = async (
  classId: string,
  studentDOB: string,
  slot: string
) => {
  try {
    const res: any = await apiForay.get(
      `/student-setup/defaultTransitions/${classId}?dateOfBirth=${studentDOB}&slot=${slot}`
    );
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("Couldn't get students info");
  } catch (err) {
    console.log("error: ", err);
  }
};

const fetchStudents = async (filters?: any) => {
  try {
    const res: any = await apiForay.get(`/student-setup?`, filters);
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("No student found");
  } catch (err) {
    console.log("error: ", err);
  }
};

const archiveStudent = async (id: string, action: boolean) => {
  try {
    const res: any = await apiForay.patch(
      `/student-setup/archiveUnarchiveStudent/${id}/${action}`
    );
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("error in updating user");
  } catch (err) {
    console.log("error: ", err);
  }
};
const updateStudentProfileImage = async (
  id: string,
  profilePictureUrl: string
) => {
  try {
    const res: any = await apiForay.patch(
      `/student-setup/updateProfilePicture/${id}`,
      { profilePictureUrl }
    );
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("error in updating user");
  } catch (err) {
    console.log("error: ", err);
  }
};

const fetchStudent = async (id: string, studentInfo?: boolean) => {
  let endPoint: string;
  if (studentInfo) endPoint = `/student-setup/student/profile/`;
  else endPoint = `/student-setup/student/`;
  try {
    const res: any = await apiForay.get(`${endPoint}${id}`);
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("student not found");
  } catch (err) {
    console.log("error: ", err);
  }
};

const fetchSiblings = async (id: string) => {
  try {
    const res: any = await apiForay.get(`/student-setup/siblings/${id}`);
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("siblings not found");
  } catch (err) {
    console.log("error: ", err);
  }
};

const fetchClassroomStudents = async (classroomId: string, filters?: any) => {
  try {
    const res: any = await apiForay.get(
      `/student-setup/students/${classroomId}`,
      filters
    );
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("student not found");
  } catch (err) {
    console.log("error: ", err);
  }
};

const updateParentInfo = async (parentId: string, parentInfo: Parent) => {
  try {
    const res: any = await apiForay.put(`/student-setup/parent/${parentId}`, {
      parent: parentInfo,
    });
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("parent not found");
  } catch (err) {
    console.log("error: ", err);
  }
};

const updateAdditionalContacts = async (
  contactId: string,
  contacts: Contact2[]
) => {
  try {
    const res: any = await apiForay.put(`/student-setup/contact/${contactId}`, {
      contacts: contacts,
    });
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("contact information not found");
  } catch (err) {
    console.log("error: ", err);
  }
};

const executeStudentTransition = async (
  studentId: string,
  transition: FirstTransition
): Promise<any> => {
  try {
    const res: any = await apiForay.post(
      `/student-setup/executeTransition/${studentId}`,
      transition
    );
    if (res?.ok) {
      return res.data;
    }
    throw new Error("Failed to fetch data");
  } catch (err) {
    console.log("error: ", err);
    return [];
  }
};

const executeStudentStarts = async (
  studentId: string,
  startAtCenterDate: string
): Promise<any> => {
  try {
    const res: any = await apiForay.post(
      `/student-setup/executeStarts/${studentId}`,
      { startAtCenter: startAtCenterDate }
    );
    if (res?.ok) {
      return res.data;
    }
    throw new Error("Failed to fetch data");
  } catch (err) {
    console.log("error: ", err);
    return [];
  }
};

const executeStudentWithdrawl = async (
  studentId: string,
  leaveDate: string
): Promise<any> => {
  try {
    const res: any = await apiForay.post(
      `/student-setup/executeWithdrawal/${studentId}`,
      { leaveDate: leaveDate }
    );
    if (res?.ok) {
      return res.data;
    }
    throw new Error("Failed to fetch data");
  } catch (err) {
    console.log("error: ", err);
    return [];
  }
};

export const studentSvc = {
  postStudentSetup,
  postSiblingSetup,
  updateStudentSetup,
  fetchStudentsInfo,
  fetchDefaultTransitions,
  fetchStudents,
  archiveStudent,
  updateStudentProfileImage,
  fetchStudent,
  fetchSiblings,
  fetchClassroomStudents,
  updateParentInfo,
  updateAdditionalContacts,
  executeStudentTransition,
  executeStudentStarts,
  executeStudentWithdrawl,
};
