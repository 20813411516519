import React, { useEffect, useState } from "react";
import { AiOutlineClockCircle } from "react-icons/ai";
import { IoCloseOutline } from "react-icons/io5";
import { LuCake } from "react-icons/lu";
import { RiUserLine } from "react-icons/ri";
import { TfiBlackboard } from "react-icons/tfi";
import {
  formatDateAmerican,
  formatDateHyphenFullYear,
  formatDateSlashFullYear,
  formatDateSpace,
} from "../../../../shared/utils/formatDate";
import { useExecuteStudentStart } from "../../../hooks/useExecuteStudentStart";
import { useExecuteStudentTransition } from "../../../hooks/useExecuteStudentTransition";
import { useExecuteStudentWithdraw } from "../../../hooks/useExecuteStudentWithdraw";
import { classroomSvc } from "../../../services/classroom.service";
import { IClassroomSetup } from "../../../types/IClassroomSetup.interface";
import { Transition } from "../../../types/IEnrollmentTrackingInfo";
import { StudentExecutions } from "../../enums/studentExecutions.enum";

interface ModalProps {
  isOpen: boolean; // Controls if the modal is open
  onClose: () => void; // Function to close the modal
  selectedTransition: Transition | undefined;
  selectedTransitionType: string | undefined;
}

const StudentExecutionDetailDialog: React.FC<ModalProps> = ({
  onClose,
  selectedTransition,
  selectedTransitionType,
}) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [classroomName, setClassroomName] = useState<string | undefined>("");
  const [tranistionClassroom, setTransitionClassroom] = useState<
    string | undefined
  >("");
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const executeStudentStart = useExecuteStudentStart();
  const executeStudentTransition = useExecuteStudentTransition();
  const executeStudentWithdraw = useExecuteStudentWithdraw();

  const fetchClassrooms = async () => {
    const resp = await classroomSvc.fetchClassrooms();
    console.log("resp", resp?.data);

    if (resp?.data) {
      const classroom = resp.data.existingClassroomSetups.find(
        (clasroom: IClassroomSetup) =>
          clasroom._id === selectedTransition?.classroom
      );
      console.log("classroom", classroom);
      const transitionClassroom = resp.data.existingClassroomSetups.find(
        (classroom: IClassroomSetup) =>
          classroom._id ===
          selectedTransition?.transitions.firstTransition.classroomId
      );
      setClassroomName(classroom?.classroomName);
      setTransitionClassroom(transitionClassroom.classroomName);
    }
  };

  const onExecute = () => {
    if (selectedTransition) {
      if (selectedTransitionType === StudentExecutions.Start) {
        executeStudentStart.mutate(
          {
            studentId: selectedTransition._id,
            startDate: selectedTransition?.Dates.startAtCenter as string,
          },
          {
            onSuccess: (data) => {
              console.log("Executed Student", data);
              onClose();
            },
          }
        );
      } else if (
        selectedTransitionType === StudentExecutions.Transition_In ||
        selectedTransitionType === StudentExecutions.Transition_Out
      ) {
        executeStudentTransition.mutate(
          {
            studentId: selectedTransition._id,
            transition: selectedTransition.transitions.firstTransition,
          },
          {
            onSuccess: (data) => {
              console.log("Executed Student", data);
              onClose();
            },
          }
        );
      } else if (selectedTransitionType === StudentExecutions.Withdraw) {
        const formatedDate = formatDateSlashFullYear(
          selectedTransition.Dates.leaveDate as string
        );
        if (formatedDate) {
          executeStudentWithdraw.mutate(
            {
              studentId: selectedTransition._id,
              leaveDate: formatedDate,
            },
            {
              onSuccess: (data) => {
                console.log("Executed Student", data);
                onClose();
              },
            }
          );
        }
      }
    }
  };

  useEffect(() => {
    fetchClassrooms();
  }, []);

  return (
    <div className="fixed inset-0 bg-secondaryVariant bg-opacity-20 flex justify-center items-center z-[9999]">
      <div className="bg-white p-9 rounded-3xl shadow-lg w-[750px] relative">
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 absolute right-4 top-4"
        >
          <IoCloseOutline className="w-6 h-6 text-secondary" />
        </button>
        <h3 className="text-2xl text-secondary font-semibold mb-6">
          Are you sure you want to Execute this student?
        </h3>
        <div className="grid grid-cols-2 gap-6 mb-6 md:mb-12">
          <div className="flex items-center gap-3">
            <strong className="text-base text-secondaryVariant font-medium flex items-center gap-2">
              <RiUserLine className="w-6 h-6" />
              Name:
            </strong>
            <span className="text-base text-secondary">
              {selectedTransition?.firstName} {selectedTransition?.lastName}
            </span>
          </div>
          <div className="flex items-center gap-3">
            <strong className="text-base text-secondaryVariant font-medium flex items-center gap-2">
              <LuCake className="w-6 h-6" />
              DOB:
            </strong>
            <span className="text-base text-secondary">
              {formatDateSpace(selectedTransition?.dateOfBirth as string)}
            </span>
          </div>
          <div className="flex items-center gap-3">
            <strong className="text-base text-secondaryVariant font-medium flex items-center gap-2">
              <TfiBlackboard className="w-6 h-6" />
              Classroom:
            </strong>
            <span className="text-base text-secondary">{classroomName}</span>
          </div>
          <div className="flex items-center gap-3">
            <strong className="text-base text-secondaryVariant font-medium flex items-center gap-2">
              <AiOutlineClockCircle className="w-6 h-6" />
              Timing Slot:
            </strong>
            <span className="text-base text-secondary">
              {selectedTransition?.transitions.firstTransition.slot}
            </span>
          </div>
          <div className="flex items-center gap-3">
            <strong className="text-base text-secondaryVariant font-medium flex items-center gap-2">
              <img src="../images/move-right.svg" alt="move to right" />
              Transition date:
            </strong>
            <span className="text-base text-secondary">
              {formatDateSlashFullYear(
                selectedTransition?.transitions.firstTransition.date as string
              )}
            </span>
          </div>
          <div className="flex items-center gap-3">
            <strong className="text-base text-secondaryVariant font-medium flex items-center gap-2">
              <TfiBlackboard className="w-6 h-6" />
              Transition Classroom:
            </strong>
            <span className="text-base text-secondary">
              {tranistionClassroom}
            </span>
          </div>
        </div>
        <div className="flex items-center gap-3">
          <button
            className="btnPrimary max-w-[182px] flex justify-center"
            onClick={onExecute}
          >
            Yes, Execute
          </button>
          <button onClick={onClose} className="btnSimple max-w-[172px]">
            No, Stay There
          </button>
        </div>
      </div>
    </div>
  );
};

export default StudentExecutionDetailDialog;
