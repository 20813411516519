import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../../../hr-setup_modules/components/Breadcrumb";
import DashboardWrapper from "../../../../shared/components/layout/DashboardWrapper";
import InputField from "../../../components/inputsFields/InputField";
import ReactSelect from "../../../components/reactSelect/ReactSelect";
import { forayRoutes } from "../../../routes/routes";
import { IStudentInfo } from "../../types/IStudentInfo";
import {
  calculateAgeYM,
  formatDateSlashFullYear,
  revertToDateType,
} from "../../../../shared/utils/formatDate";
import {
  IModifySlotsOptions,
  Transition,
  Transitions,
} from "../../../types/IStudentSetup.interface";
import { studentSvc } from "../../../services/student.service";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  IClassroomSetup,
  PartTimeSlots,
} from "../../../types/IClassroomSetup.interface";
import { classroomSvc } from "../../../services/classroom.service";
import { Slot } from "../../../enums/Foray.enums";
import { IOption } from "../../../types/ICenterSetup.interface";
import ReactDatePicker from "../../../../shared/components/reactDatePicker/ReactDatePicker";
import RequiredAsterisk from "../../../../shared/components/ui/RequiredAsterisk";
import { EnrollmentTrackingSvc } from "../../services/enrollment.service";
import { showSuccessToast } from "../../../../shared/utils/toast";
import { QueryKeyString } from "../../enums/queryKey.enum";
import { useEditStudentPreview } from "../../hooks/useEditStudentPreview";

const breadcrumbItems = [
  { label: "Foray", link: forayRoutes.foray_screen },
  {
    label: "Enrollment Tracking & Optimization",
    link: forayRoutes.enrollment_tracking,
  },
  { label: "Student Detail Edit", isCurrent: true },
];

const EditStudentDetail = () => {
  const queryClient = useQueryClient();
  const { state } = useLocation();
  const [studentInfo, setStudentInfo] = useState<IStudentInfo | undefined>(
    state.student
  );
  const [defaultTransitions, setDefaultTransition] = useState<Transitions>();
  const [classroomOptions, setClassroomOptions] = useState<IOption[]>();
  const [modifySlotOptions, setModifySlotOptions] =
    useState<IModifySlotsOptions>({
      firstT: null,
      secondT: null,
      thirdT: null,
    });
  const navigate = useNavigate();
  console.log("check: ", studentInfo);

  const EditStudentPreview = useEditStudentPreview();

  const handleGetAge = (dob: string) => {
    const { years, months } = calculateAgeYM(dob);
    return `${years}Y ${months}M`;
  };
  const { data: classroomSetups } = useQuery<IClassroomSetup[]>({
    queryKey: ["get-classroom-setups"],
    queryFn: async () => {
      // if (!students?.length) return
      const res = await classroomSvc.fetchClassrooms();

      if (res?.ok) {
        return res?.data?.existingClassroomSetups;
      }
      return [];
    },
  });

  const handleGetClassroomName = (classroomId: string) => {
    if (!classroomId) return "";
    return (
      classroomSetups?.find((classroom) => classroom._id === classroomId)
        ?.classroomName || classroomId
    );
  };

  const handleGetTransitionSlotName = (transition: Transition) => {
    if (transition?.slot === Slot.FULL_TIME) {
      return Slot.FULL_TIME;
    }
    return (
      classroomSetups
        ?.find((classroom) => classroom._id === transition?.classroomId)
        ?.partTimeSlots?.find((slot) => slot?.uuid === transition?.slot)
        ?.name || transition?.slot
    );
  };

  const getDefaultTransitions = async () => {
    const res = await studentSvc.fetchDefaultTransitions(
      studentInfo?.classroom as string,
      studentInfo?.dateOfBirth as string,
      studentInfo?.slot as string
    );
    if (res?.ok) {
      setDefaultTransition(res?.data);
    }
  };

  useEffect(() => {
    if (studentInfo?.dateOfBirth && studentInfo?.classroom && studentInfo?.slot)
      getDefaultTransitions();
  }, [studentInfo?._id]);

  useEffect(() => {
    setClassroomOptions(
      classroomSetups?.map((classroom) => ({
        value: classroom._id as string,
        label: classroom.classroomName,
      }))
    );
  }, [classroomSetups]);

  const handleModifySlotsOpt = async (
    transNumber: number,
    classroomId: string
  ) => {
    const res = await classroomSvc.fetchTransitionSlots(classroomId);
    if (res?.ok) {
      if (transNumber === 0)
        setModifySlotOptions((prev) => ({
          ...prev,
          firstT: [
            { value: Slot.FULL_TIME, label: Slot.FULL_TIME },
            ...res?.data?.slots?.map((slot: PartTimeSlots) => ({
              value: slot?.uuid,
              label: slot?.name,
            })),
          ],
        }));
      if (transNumber === 1)
        setModifySlotOptions((prev) => ({
          ...prev,
          secondT: [
            { value: Slot.FULL_TIME, label: Slot.FULL_TIME },
            ...res?.data?.slots?.map((slot: PartTimeSlots) => ({
              value: slot?.uuid,
              label: slot?.name,
            })),
          ],
        }));
      if (transNumber === 2)
        setModifySlotOptions((prev) => ({
          ...prev,
          thirdT: [
            { value: Slot.FULL_TIME, label: Slot.FULL_TIME },
            ...res?.data?.slots?.map((slot: PartTimeSlots) => ({
              value: slot?.uuid,
              label: slot?.name,
            })),
          ],
        }));
    }
  };

  useEffect(() => {
    if (!state?.student?._id) return;

    const transitions = [
      state?.student?.transitions?.firstTransition,
      state?.student?.transitions?.secondTransition,
      state?.student?.transitions?.thirdTransition,
    ];

    transitions.forEach((transition, index) => {
      if (transition?.classroomId) {
        handleModifySlotsOpt(index, transition.classroomId);
      }
    });

    setStudentInfo(state?.student);
  }, [state?.student]);

  const handleTransitions = (
    transitionKey: string,
    field: string,
    value: any
  ) => {
    setStudentInfo((prev: any) => ({
      ...prev,
      transitions: {
        ...prev.transitions,
        [transitionKey]: {
          ...prev.transitions[transitionKey],
          [field]: value,
        },
      },
    }));
  };

  const onSave = async () => {
    if (!state?.previewId || !studentInfo?._id) return;
    const reqBody = {
      firstTransition: {
        classroomId:
          studentInfo?.transitions?.firstTransition?.classroomId || null,
        slot: studentInfo?.transitions?.firstTransition?.slot || "",
        date:
          formatDateSlashFullYear(
            studentInfo?.transitions?.firstTransition?.date
          ) || null,
      },
      secondTransition: {
        classroomId:
          studentInfo?.transitions?.secondTransition?.classroomId || null,
        slot: studentInfo?.transitions?.secondTransition?.slot || "",
        date:
          formatDateSlashFullYear(
            studentInfo?.transitions?.secondTransition?.date
          ) || null,
      },
      thirdTransition: {
        classroomId:
          studentInfo?.transitions?.thirdTransition?.classroomId || null,
        slot: studentInfo?.transitions?.thirdTransition?.slot || "",
        date:
          formatDateSlashFullYear(
            studentInfo?.transitions?.thirdTransition?.date
          ) || null,
      },
    };
    console.log("check edit student body: ", reqBody);
    // const res = await EnrollmentTrackingSvc.editStudentPreview(
    //   state?.previewId as string,
    //   studentInfo?._id,
    //   reqBody
    // );
    EditStudentPreview.mutate(
      {
        previewId: state?.previewId,
        studentId: studentInfo?._id,
        reqBody: reqBody,
      },
      {
        onSuccess: (data) => {
          showSuccessToast("Student updated successfully.");
          navigate(forayRoutes.enrollment_tracking, {
            state: { previewId: state?.previewId },
          });
        },
      }
    );

    // if (res) {
    //   // await queryClient.invalidateQueries({
    //   //   queryKey: [QueryKeyString.Student_Previews],
    //   // });
    //   showSuccessToast("Student updated successfully.");
    //   navigate(forayRoutes.enrollment_tracking, {
    //     state: { previewId: state?.previewId },
    //   });
    // }
  };

  const handleCancel = () => {
    navigate(forayRoutes.enrollment_tracking, {
      state: { previewId: state?.previewId },
    });
  };

  return (
    <>
      <DashboardWrapper>
        <div className="max-w-[1082px] flex justify-center items-center p-6 mx-auto ">
          {/* First Section */}
          <div>
            <Breadcrumb items={breadcrumbItems} />
            <div className="overflow-y-scroll h-[calc(100vh-155px)] custom-scrollbar">
              <h3 className="text-[28px] text-secondary font-bold my-9">
                Student Detail Edit
              </h3>
              <div className="grid grid-cols-3 gap-x-6 gap-y-9">
                <div className="w-full">
                  <InputField
                    label="Name"
                    type="text"
                    value={studentInfo?.firstName || ""}
                    disabled
                    placeholder={"Name"}
                  />
                </div>
                <div className="w-full">
                  <InputField
                    placeholder={""}
                    label="Age"
                    type="text"
                    value={
                      handleGetAge(studentInfo?.dateOfBirth as string) || ""
                    }
                    disabled
                  />
                </div>

                <div className="w-full">
                  <InputField
                    placeholder={""}
                    label="Start at Center"
                    type="text"
                    value={
                      formatDateSlashFullYear(
                        studentInfo?.Dates?.startAtCenter as string
                      ) || ""
                    }
                    disabled
                  />
                </div>
              </div>
              <hr className="text-secondaryVariant2 my-9" />
              {/* Second Section */}

              <h3 className="text-secondaryVariant text-xl mb-6">
                Transition 1 (Default)
              </h3>
              <div className="grid grid-cols-3 gap-x-6 gap-y-9 mb-9">
                <div className="field">
                  <InputField
                    key={studentInfo?.transitions?.firstTransition.date}
                    label="Transition 1 Classroom"
                    placeholder="Select classroom "
                    type="text"
                    value={handleGetClassroomName(
                      defaultTransitions?.firstTransition?.classroomId as string
                    )}
                    // onChange={(selectedOption) => {
                    //   handleTransitions("transition1", "modifyClassroom", selectedOption?.value as string);
                    //   handleModifySlotsOpt(1, selectedOption?.value as string);
                    // }}
                    required
                    disabled
                  />
                </div>
                <div>
                  <InputField
                    label="Transition 1 Session"
                    type="text"
                    placeholder=" "
                    value={
                      handleGetTransitionSlotName(
                        defaultTransitions?.firstTransition as Transition
                      ) || ""
                    }
                    required
                    disabled
                  />
                </div>
                <InputField
                  placeholder={""}
                  label="Transition 1 Date"
                  type="text"
                  value={
                    formatDateSlashFullYear(
                      defaultTransitions?.firstTransition?.date as string
                    ) || ""
                  }
                  required
                  disabled
                />
              </div>

              <h3 className="text-secondaryVariant text-xl mb-6">
                Transition 1 (modified)
              </h3>
              <div className="grid grid-cols-3 gap-x-6 gap-y-9 mb-9">
                <div className="field">
                  <div>
                    <ReactSelect
                      label="Transition 1 Classroom"
                      placeholder="Transition 1 Classroom "
                      options={classroomOptions}
                      value={classroomOptions?.find(
                        (option) =>
                          option.value ===
                          studentInfo?.transitions?.firstTransition?.classroomId
                      )}
                      onChange={(selectedOption) => {
                        handleTransitions(
                          "firstTransition",
                          "classroomId",
                          selectedOption?.value as string
                        );
                        handleModifySlotsOpt(
                          0,
                          selectedOption?.value as string
                        );
                      }}
                      required
                    />
                  </div>
                </div>
                <div className="field">
                  {/* <label>Transition 1 Session</label> */}
                  <ReactSelect
                    label={"Transition 1 Session"}
                    placeholder={""}
                    options={modifySlotOptions?.firstT || undefined}
                    value={modifySlotOptions?.firstT?.find(
                      (option) =>
                        option.value ===
                        studentInfo?.transitions?.firstTransition?.slot
                    )}
                    onChange={(selectedOption) => {
                      handleTransitions(
                        "firstTransition",
                        "slot",
                        selectedOption?.value as string
                      );
                    }}
                    required
                  />
                </div>
                <div className="field">
                  <label htmlFor="" className="text-base text-secondaryVariant">
                    Transition 1 Date
                    <RequiredAsterisk />
                  </label>
                  <div className="mt-1">
                    <ReactDatePicker
                      key={studentInfo?.transitions?.firstTransition?.date}
                      placeholder="MM/DD/YYYY"
                      value={revertToDateType(
                        studentInfo?.transitions?.firstTransition
                          ?.date as string
                      )}
                      onChange={(date) =>
                        handleTransitions(
                          "firstTransition",
                          "date",
                          date?.toString() || null
                        )
                      }
                    />
                  </div>
                </div>
              </div>
              {/* Third Section */}
              <hr className="text-secondaryVariant2 my-9" />

              <h3 className="text-secondaryVariant text-xl mb-6">
                Transition 2 (default)
              </h3>
              <div className="grid grid-cols-3 gap-x-6 gap-y-9 mb-9">
                <div className="field">
                  <InputField
                    key={defaultTransitions?.secondTransition?.classroomId}
                    label="Transition 2 Classroom"
                    type="text"
                    placeholder="Select classroom "
                    value={
                      handleGetClassroomName(
                        defaultTransitions?.secondTransition
                          ?.classroomId as string
                      ) || ""
                    }
                    required
                    disabled
                    // onChange={(selectedOption) => {
                    //   handleTransitions("transition3", "modifyClassroom", selectedOption?.value as string);
                    //   handleModifySlotsOpt(1, selectedOption?.value as string);
                    // }}
                  />
                </div>
                <div className="field">
                  <InputField
                    key={defaultTransitions?.secondTransition?.classroomId}
                    label="Transition 2 Session"
                    placeholder=""
                    type="text"
                    value={
                      handleGetTransitionSlotName(
                        defaultTransitions?.secondTransition as Transition
                      ) || ""
                    }
                    required
                    disabled
                    // onChange={(selectedOption) => {
                    //   handleTransitions("transition3", "modifyClassroom", selectedOption?.value as string);
                    //   handleModifySlotsOpt(1, selectedOption?.value as string);
                    // }}
                  />
                </div>
                <InputField
                  placeholder={""}
                  label="Transition 2 Date"
                  type="text"
                  value={
                    formatDateSlashFullYear(
                      defaultTransitions?.secondTransition?.date as string
                    ) || ""
                  }
                  required
                  disabled
                />
              </div>

              <h3 className="text-secondaryVariant text-xl mb-6">
                Transition 2 (modified)
              </h3>
              <div className="grid grid-cols-3 gap-x-6 gap-y-9 mb-16">
                <div className="field">
                  <ReactSelect
                    key={studentInfo?.transitions?.secondTransition.classroomId}
                    label="Transition 2 Classroom"
                    placeholder="Select classroom "
                    // required
                    options={classroomOptions}
                    value={classroomOptions?.find(
                      (option) =>
                        option?.value ===
                        studentInfo?.transitions?.secondTransition.classroomId
                    )}
                    required
                    onChange={(selectedOption) => {
                      handleTransitions(
                        "secondTransition",
                        "classroomId",
                        selectedOption?.value as string
                      );
                      handleModifySlotsOpt(1, selectedOption?.value as string);
                    }}
                  />
                </div>
                <div className="field">
                  <ReactSelect
                    label={"Transition 2 Session"}
                    placeholder={"Select slot"}
                    options={modifySlotOptions?.secondT || undefined}
                    value={modifySlotOptions?.secondT?.find(
                      (option) =>
                        option.value ===
                        studentInfo?.transitions.secondTransition.slot
                    )}
                    onChange={(selectedOption) => {
                      handleTransitions(
                        "secondTransition",
                        "slot",
                        selectedOption?.value as string
                      );
                    }}
                    required
                  />
                </div>
                <div className="field">
                  <label htmlFor="" className="text-base text-secondaryVariant">
                    Transition 2 Date
                    <RequiredAsterisk />
                  </label>
                  <div className="mt-1">
                    <ReactDatePicker
                      key={studentInfo?.transitions?.secondTransition?.date}
                      placeholder="MM/DD/YYYY"
                      value={revertToDateType(
                        studentInfo?.transitions?.secondTransition
                          ?.date as string
                      )}
                      onChange={(date) =>
                        handleTransitions(
                          "secondTransition",
                          "date",
                          date?.toString() || null
                        )
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <button className="btnPrimary max-w-[114px]" onClick={onSave}>
                  Save
                </button>
                <button
                  className="btnSimple max-w-[114px]"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </DashboardWrapper>
    </>
  );
};

export default EditStudentDetail;
