import React, { useState } from "react";
import { MdOutlineCalendarToday } from "react-icons/md";
import { BiAdjust } from "react-icons/bi";
import { PiHourglassHighLight } from "react-icons/pi";
import { HiOutlineLockOpen } from "react-icons/hi";
import { MdInsertChartOutlined } from "react-icons/md";

const tabsData = [
  { icon: <MdOutlineCalendarToday />, label: "Daily Schedule/Breaks" },
  { icon: <BiAdjust />, label: "Turnover Mng" },
  { icon: <PiHourglassHighLight />, label: "Hrs Worked" },
  { icon: <HiOutlineLockOpen />, label: "Cond. Vionls" },
  { icon: <MdInsertChartOutlined />, label: "Evaluation" },
];

const FilterTabs = ({ tabs = tabsData }) => {

    const [activeTab, setActiveTab] = useState<number>(0);
  return (
    <div className="flex flex-wrap gap-2">
      {tabs.map((tab, index) => (
        <button
          key={index}
          onClick={() => setActiveTab(index)}
          className={`${
            activeTab === index ? "bg-black text-white" : "bg-white text-black"
          } flex items-center gap-2 p-3 rounded-lg hover:bg-secondary transition-all duration-300 hover:text-white`}
        >
          <i className="text-xl">{tab.icon}</i> {tab.label}
        </button>
      ))}
    </div>
  );
};

export default FilterTabs;
