import React from "react";
import {
  FiArrowDown,
  FiArrowDownRight,
  FiArrowUp,
  FiArrowUpRight,
} from "react-icons/fi";
import { EnrollmentTrackingSvc } from "../../services/enrollment.service";
import { IEnrollmentSwitchesState } from "../../types/IEnrollmentSwitches";
import WarningModal from "../modals/WarningModal";

interface IProps {
  sliderValue: any;
  setSliderValue: any;

  currentDayStarts: any[] | undefined;
  currentDayTransitions: any[] | undefined;
  currentDayWithdrawals: any[] | undefined;
  tomorrowStarts: any[] | undefined;
  tomorrowTransitions: any[] | undefined;
  tomorrowWithdrawals: any[] | undefined;
  switches: IEnrollmentSwitchesState;
  onCancel: (switchName: string) => void;
  onDiscardChanges: () => void;
  showModal: boolean;
  previewID: string | undefined;
  closeModal: () => void;
}
const AutoMovements: React.FC<IProps> = ({
  sliderValue,
  setSliderValue,
  currentDayStarts,
  currentDayTransitions,
  currentDayWithdrawals,
  tomorrowStarts,
  tomorrowTransitions,
  tomorrowWithdrawals,
  switches,
  showModal,
  onCancel,
  onDiscardChanges,
  previewID,
  closeModal,
}) => {
  const onSave = async () => {
    if (previewID) {
      const resp = await EnrollmentTrackingSvc.saveChanges(previewID);
      console.log("resp", resp);
    }

    closeModal();
  };

  return (
    <>
      <div className="bg-white rounded-lg p-2">
        <div className="flex  gap-2">
          <div className="bg-secondaryNeutral py-2 px-3 rounded-lg flex items-start w-[471px] 2xl:w-full 2xl:flex-initial flex-1">
            <div className="flex flex-col col-span-4 w-full items-start ">
              <h4 className="text-sm text-primary font-semibold w-32">
                Auto Movements
              </h4>
              <span className="text-sm text-secondaryVariant">Tomorrow:</span>
              <span className="text-sm text-secondaryVariant">Today:</span>
            </div>
            <div className="flex flex-col col-span-2 w-full items-center">
              <h4 className="text-sm text-primary font-semibold flex items-center gap-0.5">
                <FiArrowDown className="w-3 h-3" />
                Starts{" "}
              </h4>
              <span className="text-sm text-secondaryVariant">
                {tomorrowStarts?.length ?? 0}
              </span>
              <span className="text-sm text-secondaryVariant">
                {currentDayStarts?.length ?? 0}
              </span>
            </div>
            <div className="flex flex-col col-span-3 w-full items-center">
              <h4 className="text-sm text-primary font-semibold flex items-center gap-0.5">
                <FiArrowDownRight className="w-3 h-3" />
                Transitions <FiArrowUpRight className="w-3 h-3" />
              </h4>
              <span className="text-sm text-secondaryVariant">
                {tomorrowTransitions?.length ?? 0}
              </span>
              <span className="text-sm text-secondaryVariant">
                {currentDayTransitions?.length ?? 0}
              </span>
            </div>
            <div className="flex flex-col col-span-3 w-full items-center">
              <h4 className="text-sm text-primary font-semibold flex items-center gap-0.5">
                <FiArrowUp className="w-3 h-3" />
                Withdrawals{" "}
              </h4>
              <span className="text-sm text-secondaryVariant">
                {tomorrowWithdrawals?.length ?? 0}
              </span>
              <span className="text-sm text-secondaryVariant">
                {currentDayWithdrawals?.length ?? 0}
              </span>
            </div>
          </div>
          <div className="w-[444px] 2xl:w-full 2xl:flex-initial flex-1">
            {/* optimization */}
            <div>
              {switches.optimization && (
                <div className="flex items-center gap-3 justify-end mb-1.5">
                  <button
                    onClick={() => onCancel("optimization")}
                    className="text-base text-secondary border border-secondaryVariant font-semibold py-0.5 px-3 rounded-xl"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={onSave}
                    className="bg-primary text-white text-base font-semibold px-3.5 py-0.5 rounded-xl border"
                  >
                    Save
                  </button>
                </div>
              )}
              {/* <div className="bg-secondaryNeutral p-3 rounded-lg flex items-center gap-2">
              <HiOutlineTv className="text-secondaryVariant w-6 h-6" />
              <span className="text-sm text-secondaryVariant">Min</span>
              <ScrollSlider
                sliderValue={sliderValue}
                onSliderChange={setSliderValue}
              />
              <span className="text-sm text-secondaryVariant">Max</span>
            </div> */}
            </div>
            <div
              className={`bg-secondaryNeutral py-3 rounded-lg flex items-center 2xl:justify-center 2xl:gap-10 gap-8 2xl:px-10 px-5 ${
                switches.optimization ? "" : "h-full"
              }`}
            >
              <div className="flex items-center gap-1">
                <span className="w-4 h-3 bg-statusColor rounded rounded-l-full block"></span>{" "}
                Vacancy
              </div>
              <div className="flex items-center gap-1">
                <span className="w-4 h-3 bg-overBooked rounded rounded-l-full block"></span>{" "}
                Over-Booked
              </div>
              <div className="flex items-center gap-1">
                <span className="w-4 h-3 bg-white rounded rounded-l-full block"></span>{" "}
                Full
              </div>
              <div className="flex items-center gap-1">
                <span className="w-4 h-3 bg-ageViolation rounded rounded-l-full block"></span>{" "}
                Age Violation
              </div>
            </div>
          </div>
        </div>
        {showModal && (
          <WarningModal previewID={previewID} onClose={onDiscardChanges} />
        )}
      </div>
    </>
  );
};

export default AutoMovements;
