import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QueryKeyString } from "../enrollment_Tracking/enums/queryKey.enum";
import { studentSvc } from "../services/student.service";

export const useExecuteStudentWithdraw = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      studentId,
      leaveDate,
    }: {
      studentId: string;
      leaveDate: string;
    }) => studentSvc.executeStudentWithdrawl(studentId, leaveDate),
    onError: (err) => {
      console.error("error:", err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeyString.Yearly_Enrollment],
      });
    },
  });
};
