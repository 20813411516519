import React from "react";
import ReactSelect from "../../../foray_module/components/reactSelect/ReactSelect";
import { IoIosAddCircleOutline } from "react-icons/io";

const EducationNotification = () => {
  return (
    <>
      <div className="bg-white p-6 rounded-xl">
        <h2 className="text-xl text-secondary font-semibold mb-9">
          Education{" "}
        </h2>
        <div className="grid grid-cols-2 gap-x-7 gap-y-9 mb-6">
          <div>
            <ReactSelect
              label="Education Level*"
              placeholder="Education Level* "
              required
            />
          </div>
          <div>
            <label className="text-secondaryVariant text-base mb-1 block">
              University{" "}
            </label>
            <input
              type="text"
              className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
            />
          </div>
        </div>
        <button
          className="text-base text-statusColor flex items-center gap-2 mb-9"
          type="button"
        >
          <IoIosAddCircleOutline className="w-6 h-6" />
          Add Education
        </button>
        <h2 className="text-xl text-secondary font-semibold mb-9">
          Certification{" "}
        </h2>
        <div className="grid grid-cols-2 gap-x-7 gap-y-9 mb-6">
          <div>
            <ReactSelect
              label="Certificate*"
              placeholder="Education Level* "
              required
            />
          </div>
          <div>
            <label className="text-secondaryVariant text-base mb-1 block">
              Issuing organization {" "}
            </label>
            <input
              type="text"
              className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
            />
          </div>
        </div>
        <button
          className="text-base text-statusColor flex items-center gap-2 mb-9"
          type="button"
        >
          <IoIosAddCircleOutline className="w-6 h-6" />
          Add Certificate{" "}
        </button>
        <div className="grid grid-cols-2 gap-x-7 gap-y-9 mb-9">
          <div>
            <ReactSelect
              label="Required CEU Units (Per Year)"
              placeholder="Education Level* "
              required
            />
          </div>
        </div>
        <div className="flex items-center gap-3">
          <button className="btnPrimary max-w-[181px]">Save</button>
          <button className="btnSimple max-w-[181px]">Cancel</button>
        </div>
      </div>
    </>
  );
};

export default EducationNotification;
