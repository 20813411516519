import React from "react";

interface CardHrDashboardProps {
  id: number;
  icon: JSX.Element;
  title: string;
  selectedTile: number;
  onTileClick: (id: number) => void; // Directly use `id` as parameter
}

const CardHrDashboard = ({
  icon,
  title,
  onTileClick,
  selectedTile,
  id,
}: CardHrDashboardProps) => {
  const isSelected = id === selectedTile;

  return (
    <div
      onClick={(e) => {
        e.preventDefault();
        onTileClick(id); // Directly pass the `id`
      }}
      className={`rounded-xl p-6 overflow-hidden relative group transition-all duration-300 cursor-pointer
      ${isSelected ? "bg-primary" : "bg-white"}
      ${isSelected ? "hover:bg-white" : "hover:bg-primary"}`}
    >
      <div className="relative z-10">
        <div
          className={`mb-3 transition-all duration-300
          ${isSelected ? "text-secondary" : "text-primary"}
          ${
            isSelected
              ? "group-hover:text-primary"
              : "group-hover:text-secondary"
          }`}
        >
          {icon}
        </div>
        <h3 className={`text-2xl font-semibold ${isSelected ? "text-black" : "text-black"}`}>
          {title}
        </h3>
      </div>
      <img
        src="./images/object.svg"
        alt=""
        className="absolute top-0 right-0"
      />
    </div>
  );
};

export default CardHrDashboard;
