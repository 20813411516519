import React from 'react'
import { Accordion, Dropdown } from 'react-bootstrap'
import { LuListFilter } from "react-icons/lu";
import FillterItems from './FillterItems';

const FilterButton = () => {
    const accordionData = [
        {
          eventKey: "0",
          title: "Classrooms for Infants",
          options: ["0 - 6 months", "7 - 12 months", "13 - 18 years","13 - 18 years","19 - 23 years","24 - 35 years","36 - 48 years"]
        },
        {
          eventKey: "1",
          title: "Job Title",
          options: ["Aide", "Assistant Teacher", "Lead Teacher"]
        },
        {
          eventKey: "2",
          title: "Full/Part Time",
          options: ["Full Time", "Part Time"]
        }
      ];
  return (
    <div>
         <Dropdown className="d-inline mx-2 relative" autoClose="outside">
        <Dropdown.Toggle id="dropdown-autoclose-outside">
          <LuListFilter size={24} className='text-secondary'/>
        </Dropdown.Toggle>

        <Dropdown.Menu className='flex items-center flex-col top-[100%] right-[0_!important] left-auto  z-10  p-3 w-full px-4 bg-white shadow-customShadow rounded-lg absolute min-w-[253px]'>
        <Accordion defaultActiveKey={['0']} alwaysOpen>
                {accordionData.map((item) => (
                    <FillterItems
                    key={item.eventKey}
                    eventKey={item.eventKey}
                    title={item.title}
                    options={item.options}
                    />
                ))}
    </Accordion>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export default FilterButton