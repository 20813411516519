import { Nav, Tab } from "react-bootstrap";
import { CiSearch } from "react-icons/ci";
import { GoPlus } from "react-icons/go";
import EmployeeCurrent from "./tables/EmployeeCurrent";
import FilterButton from "./FilterButton";


const TabContentComponent = () => {
  return (
    <Tab.Container >
      <Nav
        variant="pills"
        className="flex items-center border-b border-secondaryVariant2 mb-4"
      >
        <Nav.Item>
          <Nav.Link >Current</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link >Terminated</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link >Prospective</Nav.Link>
        </Nav.Item>
      </Nav>
      <Tab.Content>
        <Tab.Pane >
          <div className="grid grid-cols-2 gap-6">
            <button className="bg-primary text-white px-3 py-2 rounded">
              Add Employee <GoPlus />
            </button>
            <div className="flex gap-4">
              <input placeholder="Search" className="w-full p-2 border" />
              <CiSearch size={20} />
              <FilterButton />
            </div>
          </div>
          <EmployeeCurrent filterTerminated={false} showEmptySession={false} />
        </Tab.Pane>
        <Tab.Pane >
          <EmployeeCurrent filterTerminated={true} showEmptySession={false} />
        </Tab.Pane>
        <Tab.Pane >
          <EmployeeCurrent filterTerminated={false} showEmptySession={true} />
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
};

export default TabContentComponent;
