import { useMutation, useQueryClient } from "@tanstack/react-query";
import { studentSvc } from "../services/student.service";
import { FirstTransition } from "../types/IEnrollmentTrackingInfo";
import { QueryKeyString } from "../enrollment_Tracking/enums/queryKey.enum";

export const useExecuteStudentTransition = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      studentId,
      transition,
    }: {
      studentId: string;
      transition: FirstTransition;
    }) => studentSvc.executeStudentTransition(studentId, transition),
    onError: (err) => {
      console.error("error:", err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeyString.Yearly_Enrollment],
      });
    },
  });
};
