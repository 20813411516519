import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QueryKeyString } from "../enrollment_Tracking/enums/queryKey.enum";
import { studentSvc } from "../services/student.service";

export const useExecuteStudentStart = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ studentId, startDate }: { studentId: string; startDate: string }) =>
      studentSvc.executeStudentStarts(studentId, startDate),
    onError: (err) => {
      console.error("error:", err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeyString.Yearly_Enrollment],
      });
    },
  });
};
