export enum WeekDay {
  MONDAY = "Monday",
  TUESDAY = "Tuesday",
  WEDNESDAY = "Wednesday",
  THURSDAY = "Thursday",
  FRIDAY = "Friday",
  Saturday = "Saturday",
  Sunday = "Sunday",
}

export enum StudentStatuses {
  Enrolled = "Enrolled",
  RegdGS = "RegdGS",
  RegdWL = "RegdWL",
  WaitList = "WaitList",
  Withdrawn = "Withdrawn",
  Tour = "Tour",
  CA_APPROVAL_FOR_ENROLLMENT = "CA Approval for Enrollment",
  Drop_In = "Drop-in",
}

export enum SessionType {
  FullTimeOnly = "full time only",
  FullAndPartTime = "full and part time",
}

export enum Slot {
  FULL_TIME = "Full time",
  PART_TIME = "Part time"
}

export enum AGE_RANGE {
  AGE_0_TO_6_MONTHS = "0-6 months",
  AGE_7_TO_12_MONTHS = "7-12 months",
  AGE_13_TO_18_MONTHS = "13-18 months",
  AGE_19_TO_23_MONTHS = "19-23 months",
  AGE_24_TO_35_MONTHS = "24-35 months",
  AGE_36_TO_48_MONTHS = "36-48 months",
  AGE_5_TO_13_YEARS = "5-13 years",
}

export enum SchoolAgeProgram {
  BEFORE_SCHOOL = 'before school',
  AFTER_SCHOOL = 'after school',
  BEFORE_AND_AFTER_SCHOOL = 'before and after school',
  OTHER = 'other',
  NA = 'n/a',
}