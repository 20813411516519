import { FC } from "react";

interface Props {
  daysData: {
    day: string;
    date: string;
    month: string;
    year: number;
    fullDate: Date;
  }[]
}

const EnrollmentCalendar: FC<Props> = ({ daysData }) => {
  return (
    <div className="flex overflow-x-auto">
      <div className="flex">
        {daysData.map((dayObj, index) => (
          <div
            key={index}
            className={`flex items-baseline gap-1  border-b-[0.5px] ${["Sat", "Sun"].includes(dayObj.day)
              ? "bg-secondaryVariant2"
              : "bg-white"
              } border-secondaryNeutral py-2 px-2.5 w-[65px]`}
          >
            <span className="text-[10px] text-secondaryVariant">
              {dayObj.day}
            </span>
            <strong className="text-base font-semibold text-secondaryVariant">
              {dayObj.date}
            </strong>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EnrollmentCalendar;
