import { IEnrollmentTrackingInfo } from "../../types/IEnrollmentTrackingInfo";
import { apiForay } from "../../utils/axios";
import { ICreatePreview } from "../types/ICreatePreview";
import { IStudentInfo, Transitions } from "../types/IStudentInfo";

const fetchYearlyEnrollments = async (previewId?: string): Promise<IEnrollmentTrackingInfo> => {
  try {
    const res: any = await apiForay.get(`/previews/dashboard/yearly${previewId ? "?previewID=" + previewId : ""}`);
    if (res?.ok) {
      return res.data;
    }
    throw new Error("Failed to fetch data");
  } catch (err) {
    console.log("error: ", err);
    return {} as IEnrollmentTrackingInfo;
  }
};

const fetchStudents = async (studentIds: string[], previewId: string): Promise<IStudentInfo[]> => {
  try {
    const res: any = await apiForay.get(`/previews/students${previewId ? "?previewID=" + previewId : ""}`, {
      studentIds: studentIds,
    });
    if (res?.ok) {
      return res.data;
    }
    throw new Error("Failed to fetch data");
  } catch (err) {
    console.log("error: ", err);
    return [];
  }
};

const createPreview = async (): Promise<ICreatePreview> => {
  try {
    const res: any = await apiForay.post(`/previews/create`);
    if (res?.ok) {
      return res.data;
    }
    throw new Error("Failed to create preview");
  } catch (err) {
    console.log("error: ", err);
    return {} as ICreatePreview;
  }
};

const editStudentPreview = async (
  previewId: string,
  studentId: string,
  reqBody: any
): Promise<any> => {
  try {
    const res: any = await apiForay.post(
      `/previews/edit-student/${previewId}/${studentId}`, reqBody
    );
    if (res?.ok) {
      return res.data;
    }
    throw new Error("Failed to edit student");
  } catch (err) {
    console.log("error: ", err);
    return [];
  }
};

const getPreview = async (): Promise<any> => {
  try {
    const res: any = await apiForay.get(`/previews`);
    if (res?.ok) {
      return res.data;
    }
    throw new Error("Failed to fetch previews");
  } catch (err) {
    console.log("error: ", err);
    return [];
  }
};

const saveChanges = async (previewId: string): Promise<any> => {
  try {
    const res: any = await apiForay.post(`/previews/save/${previewId}`);
    if (res?.ok) {
      return res.data;
    }
    throw new Error("Failed to save changes");
  } catch (err) {
    console.log("error: ", err);
    return [];
  }
};

const discardChanges = async (previewId: string): Promise<any> => {
  try {
    const res: any = await apiForay.delete(`/previews/discard/${previewId}`);
    if (res?.ok) {
      return res.data;
    }
    throw new Error("Failed to discard changes");
  } catch (err) {
    console.log("error: ", err);
    return [];
  }
};

export const EnrollmentTrackingSvc = {
  fetchYearlyEnrollments,
  fetchStudents,
  createPreview,
  editStudentPreview,
  getPreview,
  saveChanges,
  discardChanges,
};
