import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { VscCheck } from "react-icons/vsc";

interface FilterItemsProps {
    eventKey: string;
    title: string;
    options: string[];
  }
  
  const FillterItems = ({ eventKey, title, options }: FilterItemsProps) => {
  return (
    <Accordion.Item eventKey={eventKey}>
    <Accordion.Header className='py-3 acc_btn'>
      <h4 className="flex-1">
      {title}</h4>
      <div className="w-4 h-4 icon inline-flex items-center justify-center">
          <HiOutlineChevronDown />
      </div>
    </Accordion.Header>
    <Accordion.Body>
      <ul className='space-y-3 overflow-y-scroll h-24 custom-scrollbar'>
        {options.map((option, index) => (
          <li key={index}>
            <label className="flex items-center cursor-pointer relative gap-4">
              <span className="relative inline-flex">
                <input
                  type="checkbox"
                  className="peer h-5 w-5 cursor-pointer transition-all appearance-none rounded shadow hover:shadow-md border border-slate-300 checked:bg-primary checked:border-primary"
                  id={`check-${eventKey}-${index}`}
                />
                <span className="absolute opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none">
                 <VscCheck className='text-white'/>
                </span>
              </span>
              <span className="flex-1">{option}</span>
            </label>
          </li>
        ))}
      </ul>
    </Accordion.Body>
  </Accordion.Item>
  )
}

export default FillterItems