import React, { useEffect, useState } from "react";
import {
  formatDateSlashFullYear,
  formatDateSpace,
} from "../../../../shared/utils/formatDate";
import { classroomSvc } from "../../../services/classroom.service";
import { Classroom } from "../../../types/IClassroomSetup.interface";
import {
  FirstTransition,
  SkippedStudent,
} from "../../../types/IEnrollmentTrackingInfo";
import { useExecuteStudentStart } from "../../../hooks/useExecuteStudentStart";
import { useExecuteStudentTransition } from "../../../hooks/useExecuteStudentTransition";
import { useExecuteStudentWithdraw } from "../../../hooks/useExecuteStudentWithdraw";
import { StudentExecutions } from "../../enums/studentExecutions.enum";

interface IProps {
  skippedStudents: SkippedStudent[];
  transitionType: string;
}
const SkippedPastTransitions: React.FC<IProps> = ({
  skippedStudents,
  transitionType,
}) => {
  const [classrooms, setClassrooms] = useState<Classroom[]>();
  const executeStudentStart = useExecuteStudentStart();
  const executeStudentTransition = useExecuteStudentTransition();
  const executeStudentWithdraw = useExecuteStudentWithdraw();

  useEffect(() => {
    const fetchClassrooms = async () => {
      const resp = await classroomSvc.fetchClassrooms();
      console.log(resp?.data.existingClassroomSetups);
      console.log("skippedStudents", skippedStudents);
      setClassrooms(resp?.data.existingClassroomSetups);
    };

    fetchClassrooms();
  }, []);

  const handleExecute = async (studentId: string, student: SkippedStudent) => {
    if (transitionType === StudentExecutions.Transition_In_Or_Out) {
      executeStudentTransition.mutate(
        {
          studentId: studentId,
          transition: student.transitions.firstTransition,
        },
        {
          onSuccess: (data) => {
            console.log("Executed Student", data);
          },
        }
      );
    } else if (transitionType === StudentExecutions.Start) {
      const formattedStartDate = formatDateSlashFullYear(
        student.Dates.startAtCenter as string
      );
      if (formattedStartDate) {
        executeStudentStart.mutate(
          {
            studentId: studentId,
            startDate: formattedStartDate,
          },
          {
            onSuccess: (data) => {
              console.log("Executed Student", data);
            },
          }
        );
      }
    } else if (transitionType === StudentExecutions.Withdraw) {
      executeStudentWithdraw.mutate(
        {
          studentId: studentId,
          leaveDate: student.Dates.leaveDate as string,
        },
        {
          onSuccess: (data) => {
            console.log("Executed Student", data);
          },
        }
      );
    }
  };

  return (
    <>
      <div className="border-[#AB4CFF] border rounded-xl p-6 bg-[#E6D5FF] space-y-6 mb-2">
        <p className="flex items-center gap-3 text-lg text-secondary ">
          <span>
            <img src="../images/alert-triangle.svg" alt="alert" />{" "}
          </span>
          You have skipped the past {transitionType} of below students. Do you
          want to proceed them?
        </p>
        {skippedStudents.map((student, index) => {
          const transitionClassroom = classrooms?.find((c) => {
            console.log(
              "c.id",
              c,
              "student?.transitions?.firstTransition?.classroomId",
              student?.transitions?.firstTransition?.classroomId
            );
            return c._id === student?.transitions?.firstTransition?.classroomId;
          });
          const classroom = classrooms?.find(
            (c) => c._id === student.classroom
          );
          // console.log('transitionClassroom',transitionClassroom,'classroom',classroom)
          return (
            <div>
              <h3 className="text-lg text-secondary font-semibold mb-3">
                Student {index + 1}
              </h3>
              <div className="grid grid-cols-7">
                <div className="flex flex-col items-start">
                  <span className="text-sm text-secondary mb-1">Name:</span>
                  <strong className="text-base text-secondary font-medium">
                    {student?.firstName}
                  </strong>
                </div>
                <div className="flex flex-col items-start">
                  <span className="text-sm text-secondary mb-1">DOB:</span>
                  <strong className="text-base text-secondary font-medium">
                    {formatDateSpace(student?.dateOfBirth)}
                  </strong>
                </div>
                <div className="flex flex-col items-start">
                  <span className="text-sm text-secondary mb-1">
                    Classroom:
                  </span>
                  <strong className="text-base text-secondary font-medium">
                    {classroom?.classroomName}
                  </strong>
                </div>
                <div className="flex flex-col items-start">
                  <span className="text-sm text-secondary mb-1">
                    Timing Slot:
                  </span>
                  <strong className="text-base text-secondary font-medium">
                    {student?.slot}
                  </strong>
                </div>
                {transitionType === StudentExecutions.Transition_In_Or_Out && (
                  <div className="flex flex-col items-start">
                    <span className="text-sm text-secondary mb-1">
                      Transition date:
                    </span>
                    <strong className="text-base text-secondary font-medium">
                      {formatDateSlashFullYear(
                        student?.transitions?.firstTransition?.date
                      )}
                    </strong>
                  </div>
                )}
                {transitionType === StudentExecutions.Start && (
                  <div className="flex flex-col items-start">
                    <span className="text-sm text-secondary mb-1">
                      Start at center:
                    </span>
                    <strong className="text-base text-secondary font-medium">
                      {formatDateSlashFullYear(
                        student?.Dates.startAtCenter as string
                      )}
                    </strong>
                  </div>
                )}
                {transitionType === StudentExecutions.Withdraw && (
                  <div className="flex flex-col items-start">
                    <span className="text-sm text-secondary mb-1">
                      Leave Date:
                    </span>
                    <strong className="text-base text-secondary font-medium">
                      {formatDateSlashFullYear(
                        student?.Dates.leaveDate as string
                      )}
                    </strong>
                  </div>
                )}
                {transitionType === StudentExecutions.Transition_In_Or_Out && (
                  <div className="flex flex-col items-start">
                    <span className="text-sm text-secondary mb-1">
                      Transition Classroom:
                    </span>
                    <strong className="text-base text-secondary font-medium">
                      {transitionClassroom?.classroomName}
                    </strong>
                  </div>
                )}

                <div className="flex items-end justify-end">
                  <button
                    onClick={() => handleExecute(student._id, student)}
                    className="text-lg font-medium text-white rounded-lg py-1 px-6 bg-secondary"
                  >
                    Execute
                  </button>
                </div>
              </div>
            </div>
          );
        })}
        <hr className="bg-[#AB4CFF] h-[1px]" />
      </div>
    </>
  );
};

export default SkippedPastTransitions;
