import React, { useEffect, useState } from "react";
import { TiArrowSortedDown } from "react-icons/ti";
import { Classroom } from "../../../types/IEnrollmentTrackingInfo";
import { SessionType } from "../../../enums/Foray.enums";

interface IProps {
  classroom: Classroom;
}
const SessionTime: React.FC<IProps> = ({ classroom }) => {
  const [fullTimeCount, setFullTimeCount] = useState(0);
  const [partTimeCount, setPartTimeCount] = useState(0);
  const [isCollapseTime, setIsCollapseTime] = useState(false);

  useEffect(() => {
    const counts = classroom.waitListedStudents.reduce(
      (acc, student) => {
        if (
          student.slot === "Full time" &&
          classroom.classroomId === student.classroom
        ) {
          acc.fullTime++;
        } else if (
          student.slot === "Part time" &&
          classroom.classroomId === student.classroom
        ) {
          acc.partTime++;
        }
        return acc;
      },
      { fullTime: 0, partTime: 0 }
    );

    setFullTimeCount(counts.fullTime);
    setPartTimeCount(counts.partTime);
  }, [classroom.waitListedStudents]);

  const toggleList = () => {
    setIsCollapseTime(!isCollapseTime);
  };

  return (
    <>
      <div className="bg-white ps-3 pe-2 py-1.5">
        <div className="flex items-start gap-3 pb-3">
          <div className="rounded-lg py-1 px-2 max-w-[119px] w-full bg-secondary">
            <div className="flex items-start pt-1 justify-between">
              <h3 className="text-base font-semibold text-white"></h3>
              <span className="text-white bg-primary px-1.5 rounded-3xl">
                {classroom.licenseCapacity}
              </span>
            </div>
            <strong className="text-base font-semibold text-white">
              {classroom.classroomName}
            </strong>
          </div>
          <div className="flex items-start justify-between w-full">
            <div className="bg-successAlert py-1 px-1.5 text-white text-xs font-medium rounded">
              Full Time
            </div>
            <div className="text-base text-secondary font-bold ">
              {fullTimeCount}
            </div>
          </div>
        </div>
        {SessionType.FullAndPartTime === classroom.settingType && (
          <div className="flex items-start gap-3">
            <div className="max-w-[119px] h-[60px] w-full"></div>
            <div className="flex items-start justify-between w-full">
              <div className="flex flex-col">
                <button
                  className="flex items-center gap-1"
                  onClick={toggleList}
                >
                  <span className="bg-ageViolation py-1 px-1.5 text-white text-xs font-medium rounded">
                    Part-time{" "}
                  </span>
                  <TiArrowSortedDown className="text-secondary w-6 h-6" />
                </button>
                <ul className="text-xs text-secondaryVariant2  mt-1">
                  {isCollapseTime &&
                    classroom.dailyCounts[0].partTimeSlots.map((slot) => {
                      return (
                        <li>
                          {slot.slotStartTime} to {slot.slotEndTime}
                        </li>
                      );
                    })}
                </ul>
              </div>
              <strong className="text-base text-secondary font-bold">
                {partTimeCount}
              </strong>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

// {isCollapseTime && (
//   <ul className="text-xs text-secondaryVariant2  mt-1">
//     <li>06:00 AM to 10:00 AM</li>
//     <li>10:00 AM to 02:00 PM</li>
//     <li>02:00 PM to 06:00 PM</li>
//     <li>06:00 PM to 10:00 PM</li>
//   </ul>
// )}

export default SessionTime;
