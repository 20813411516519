import React, { useState } from "react";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import { BiEdit } from "react-icons/bi";
import { GrLocation } from "react-icons/gr";
import { HiOutlineMail } from "react-icons/hi";
import { RiUserLine } from "react-icons/ri";
import { FiPhoneCall } from "react-icons/fi";
import { FiPhone } from "react-icons/fi";
import { TbBus } from "react-icons/tb";
import { HiOutlineUsers } from "react-icons/hi2";
import { CiGlobe } from "react-icons/ci";
import { HiOutlinePlusSmall } from "react-icons/hi2";
import { LuHome } from "react-icons/lu";
import { GoChevronRight } from "react-icons/go";

import ParentEdit from "../viewStudentProfile/ParentEdit";
import { Link, useLocation, useNavigate } from "react-router-dom";
import StudentsTable from "../../components/tables/StudentsTable";
import { forayRoutes } from "../../routes/routes";
import { useQuery } from "@tanstack/react-query";
import { StudentInfoDetailed } from "../../types/IStudentSetup.interface";
import { studentSvc } from "../../services/student.service";
import { calculateAge, formatDateHyphenFullYear } from "../../../shared/utils/formatDate";

const ParentInfo = () => {
  const [editingParentId, setEditingParentId] = useState<string | null>(null);

  const location = useLocation()
  const navigate = useNavigate()


  const { data: student, refetch: refetchStudent } = useQuery<StudentInfoDetailed>({
    queryKey: ["student", location.state?.studentId],
    queryFn: async () => {
      const res = await studentSvc.fetchStudent(location.state?.studentId);

      if (res?.ok) {
        console.log(res?.data?.student);
        return res?.data?.student;
      }
      return [];
    },

  });

  const { data: siblings, refetch: refetchSiblings } = useQuery<StudentInfoDetailed[]>({
    queryKey: ["siblings", student?.parents[0]._id],
    queryFn: async () => {
      if (!student?.parents?.length) return
      const res = await studentSvc.fetchSiblings(student?.parents[0]?._id as string);
      if (res?.ok) {
        console.log("siblings ", res?.data?.students);
        const fetchedStudents = res?.data?.students || [];
        return fetchedStudents.map((student: any) => ({
          id: student?._id as string,
          profilePictureUrl: student?.profilePictureUrl,
          studentName: student.firstName + " " + student.lastName,
          isActive: student.isActive,
          classroomName: student.classroom.classroomName,
          slot: student.slot,
          joiningDate: student.Dates.startAtCenter
            ? formatDateHyphenFullYear(student.Dates.startAtCenter)
            : "",
          transitionDate: student.transitions.firstTransition.date
            ? formatDateHyphenFullYear(student.transitions.firstTransition.date)
            : "",
          status: student.status,
          age: student.dateOfBirth
            ? calculateAge(student.dateOfBirth)
            : "0Y 0M 0D",
        }));
      }
      return [];
    },
    // staleTime: 0,
    // refetchOnMount: true
  });

  const handleEditingParent = (parentId: string) => {
    setEditingParentId(parentId)
  }

  const handleCancelEdit = () => {
    setEditingParentId(null)
  }

  const handleNavToStudentSetup = () => {
    navigate(forayRoutes.sibling_setup, { state: { studentId: location?.state?.studentId } })
  }

  return (
    <>
      <DashboardWrapper>
        <div className="px-6 py-5 bg-secondaryNeutral overflow-y-scroll h-[calc(100vh-85px)] custom-scrollbar">
          <div className="mb-9 flex items-center gap-2">
            <LuHome className="w-5 h-5 text-secondaryVariant" />
            <Link
              to={forayRoutes.foray_screen}
              className="text-secondaryVariant2 text-sm font-normal"
            >
              Foray
            </Link>
            <GoChevronRight className="text-secondaryVariant2 w-[18px] h-[18px]" />
            {/* <Link
              to={forayRoutes.student_list}
              className="text-secondaryVariant2 text-sm font-normal"
            >
              Center Setup{" "}
            </Link>
            <GoChevronRight className="text-secondaryVariant2 w-[18px] h-[18px]" /> */}
            <Link
              to={forayRoutes.student_list}
              className="text-secondaryVariant2 text-sm font-normal"
            >
              Student List{" "}
            </Link>
            <GoChevronRight className="text-secondaryVariant2 w-[18px] h-[18px]" />
            <p className="text-primary text-sm font-medium">
              Parent View{" "}
            </p>
          </div>
          {student?.parents?.map((parent, index) => (
            <div key={parent._id}>
              {editingParentId !== parent._id ? (
                <div className="bg-white rounded-xl p-6 mb-9">
                  <div className="flex items-center justify-between ">
                    <h3 className="text-xl text-secondary font-semibold">Parent Information</h3>
                    <button className="text-base text-statusColor flex items-center gap-2" onClick={() => handleEditingParent(parent._id)}>
                      Modify Parent
                      <BiEdit className="w-5 h-5" />
                    </button>
                  </div>
                  <hr className="h-[0.5px] bg-secondaryVariant2 my-6" />
                  <div className="grid grid-cols-3 gap-y-9">
                    <div>
                      <h3 className="text-lg text-secondary font-medium mb-2 flex items-center gap-2">
                        <RiUserLine className="w-6 h-6 text-secondaryVariant2" />
                        First Name{" "}
                      </h3>
                      <p className="text-base text-secondaryVariant">{parent.firstName}</p>
                    </div>
                    <div>
                      <h3 className="text-lg text-secondary font-medium mb-2 flex items-center gap-2">
                        <RiUserLine className="w-6 h-6 text-secondaryVariant2" />
                        Last Name{" "}
                      </h3>
                      <p className="text-base text-secondaryVariant">{parent.lastName}</p>
                    </div>
                    <div>
                      <h3 className="text-lg text-secondary font-medium mb-2 flex items-center gap-2">
                        <HiOutlineMail className="w-6 h-6 text-secondaryVariant2" />
                        Email{" "}
                      </h3>
                      <p className="text-base text-secondaryVariant">
                        {parent.email}
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg text-secondary font-medium mb-2 flex items-center gap-2">
                        <FiPhone className="w-6 h-6 text-secondaryVariant2" />
                        Phone Number{" "}
                      </h3>
                      <p className="text-base text-secondaryVariant">
                        {parent.phone}
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg text-secondary font-medium mb-2 flex items-center gap-2">
                        <CiGlobe className="w-6 h-6 text-secondaryVariant2" />
                        State{" "}
                      </h3>
                      <p className="text-base text-secondaryVariant">{parent.state}</p>
                    </div>
                    <div>
                      <h3 className="text-lg text-secondary font-medium mb-2 flex items-center gap-2">
                        <img src="../images/globe-06.svg" alt="globe" />
                        City
                      </h3>
                      <p className="text-base text-secondaryVariant">{parent.city}</p>
                    </div>
                    <div>
                      <h3 className="text-lg text-secondary font-medium mb-2 flex items-center gap-2">
                        <GrLocation className="w-6 h-6 text-secondaryVariant2" />
                        Street{" "}
                      </h3>
                      <p className="text-base text-secondaryVariant">
                        {parent.street}{" "}
                      </p>
                    </div>
                    <div>
                      <h3 className="text-lg text-secondary font-medium mb-2 flex items-center gap-2">
                        <img src="../images/globe-05.svg" alt="globe" /> ZIP Code{" "}
                      </h3>
                      <p className="text-base text-secondaryVariant">{parent.postalCode} </p>
                    </div>
                    <div>
                      <h3 className="text-lg text-secondary font-medium mb-2 flex items-center gap-2">
                        <FiPhoneCall className="w-6 h-6 text-secondaryVariant2" />
                        Emergency Contact{" "}
                      </h3>
                      <p className="text-base text-secondaryVariant">{parent.emergencyContact ? "Yes" : "No"}</p>
                    </div>
                    <div>
                      <h3 className="text-lg text-secondary font-medium mb-2 flex items-center gap-2">
                        <HiOutlineUsers className="w-6 h-6 text-secondaryVariant2" />
                        Lives With{" "}
                      </h3>
                      <p className="text-base text-secondaryVariant">{parent.livesWith ? "Yes" : "No"}</p>
                    </div>
                    <div>
                      <h3 className="text-lg text-secondary font-medium mb-2 flex items-center gap-2">
                        <TbBus className="w-6 h-6 text-secondaryVariant2" />
                        Can Pickup{" "}
                      </h3>
                      <p className="text-base text-secondaryVariant">{parent.canPickUp ? "Yes" : "No"}</p>
                    </div>
                  </div>
                </div>
              ) : (
                <ParentEdit parentInfo={student?.parents[index]} index={index} onCancel={handleCancelEdit} />
              )}
            </div>
          ))}

          <button className="btnPrimary flex items-center gap-2 max-w-[180px] mb-9" onClick={handleNavToStudentSetup}>
            Add student <HiOutlinePlusSmall className="w-5 h-5" />
          </button>
          <h2 className="text-2xl text-secondary font-semibold mb-6">
            Siblings
          </h2>
          {siblings?.length ?
            <StudentsTable students={siblings} />
            : <div className="flex justify-center text-lg font-medium text-secondaryVariant ">No Sibling</div>
          }
        </div>
      </DashboardWrapper >
    </>
  );
};

export default ParentInfo;
