import { useQuery } from "@tanstack/react-query";
import { QueryKeyString } from "../enums/queryKey.enum";
import { EnrollmentTrackingSvc } from "../services/enrollment.service";

export const useStudentPreviews = (studentIds: string[], previewId: string) => {
  return useQuery({
    queryKey: [QueryKeyString.Student_Previews, studentIds],
    queryFn: () => {
      if (!studentIds?.length) return [];
      return EnrollmentTrackingSvc.fetchStudents(studentIds, previewId);
    },
    gcTime: 0,
  });
};
