import { IClassroomSetupPayload, IGetAgeRangeInDaysPayload } from "../types/IClassroomSetup.interface";
import { apiForay } from "../utils/axios";

 const postClassroomSetup = async (reqBody: IClassroomSetupPayload) => {
    try {

        const res: any = await apiForay.post(`/classroom-setup`, reqBody);
        if (res?.ok) {
            return { data: res?.data, ok: true };
        }
        throw new Error("Couldn't create classroom setup");
    } catch (err) {
        console.log("error: ", err);
    }
};
 const updateClassroomSetup = async (id: string, reqBody: IClassroomSetupPayload) => {
    try {

        const res: any = await apiForay.patch(`/classroom-setup/${id}`, reqBody);
        if (res?.ok) {
            return { data: res?.data, ok: true };
        }
        throw new Error("Couldn't update classroom setup");
    } catch (err) {
        console.log("error: ", err);
    }
};
 const fetchClassrooms = async (filters?: any) => {
    try {
        const res: any = await apiForay.get(`/classroom-setup?`, filters);
        if (res?.ok) {
            return { data: res?.data, ok: true };
        }
        throw new Error("No classroom found");
    } catch (err) {
        console.log("error: ", err);
    }
};
 const fetchTransitionSlots = async (classId: string) => {
    try {
        const res: any = await apiForay.get(`/classroom-setup/transitionsAvailable/${classId}`);
        if (res?.ok) {
            return { data: res?.data, ok: true };
        }
        throw new Error("No classroom found");
    } catch (err) {
        console.log("error: ", err);
    }
};

 const fetchAgeRangeInDays = async (reqBody: IGetAgeRangeInDaysPayload) => {
    try {
        const res: any = await apiForay.patch(`/classroom-setup/ageRange`, reqBody);
        if (res?.ok) {
            return { data: res?.data, ok: true };
        }
        throw new Error("Couldn't get age in days");
    } catch (err) {
        console.log("error: ", err);
    }
};
 const archiveClassroom = async (id: string, action: boolean) => {
    try {
        const res: any = await apiForay.patch(`/student-setup/archiveUnarchiveClassroom/${id}/${action}`);
        if (res?.ok) {
            return { data: res?.data, ok: true };
        }
        throw new Error("Couldn't get age in days");
    } catch (err) {
        console.log("error: ", err);
    }
};


export const classroomSvc={
    postClassroomSetup,
    updateClassroomSetup,
    fetchClassrooms,
    fetchTransitionSlots,
    fetchAgeRangeInDays,
    archiveClassroom}