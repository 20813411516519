import React from 'react'
import { FaTimes } from 'react-icons/fa';

const TerminateDialog = ({  isOpen, onClose, children }:any) => {
    if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center z-[9999] bg-black bg-opacity-50">
      <div className="bg-white rounded-3xl w-[703px] p-6 relative shadow-lg">
        <div>{children}</div>
        <div className="mt-6 flex justify-center gap-3">
          <button
            className="px-4 py-2 bg-red-500 text-white rounded-lg text-lg font-medium"
            onClick={onClose}
          >
            Yes, Terminate
          </button>
          <button
            className="px-4 py-2 border border-secondary text-secondary rounded-lg text-lg font-medium"
            onClick={() => {
              onClose();
            }}
          >
            No, I don't
          </button>
        </div>
      </div>
    </div>
  )
}

export default TerminateDialog